.grocery-list {
    font-family: Arial, sans-serif;
    max-width: 400px;
    margin: 70px auto;  /* This margin was previously set to 0 auto. */
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}


.grocery-list h1 {
    text-align: center;
}

.grocery-list input {
    padding: 10px;
    width: 70%;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.grocery-list button {
    padding: 10px 15px;
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.grocery-list button:hover {
    background-color: #0056b3;
}

.grocery-list ul {
    list-style-type: none;
    padding: 0;
}

.grocery-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.grocery-list li:last-child {
    border-bottom: none;
}
.top-bar {
    background-color: #007BFF;
    color: white;
    padding: 10px 0;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}